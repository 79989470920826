// GENERAL APP ACTIONS
export const RESET_ALL_REDUCERS = "RESET_ALL_REDUCERS"

// ACTIVE ORDER ACTIONS
export const FETCH_ALL_USER_ACTIVE_ORDERS_SUCCESS =
  "FETCH_ALL_USER_ACTIVE_ORDERS_SUCCESS"
export const FETCH_ALL_USER_ACTIVE_ORDERS_FAIL =
  "FETCH_ALL_USER_ACTIVE_ORDERS_FAIL"
export const SET_RENTAL_ORDER = "SET_RENTAL_ORDER"

// LOCKERS WEB-SOCKET COMMUNICATION
export const ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT =
  "ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT"
export const ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT_SUCCESS_RESPONSE =
  "ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT_SUCCESS_RESPONSE"
export const ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT_FAIL =
  "ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT_FAIL"
export const ON_LOCKER_LOCK_LISTENER_RESPONSE =
  "ON_LOCKER_LOCK_LISTENER_RESPONSE"
export const ON_LOCKER_LOCK_LISTENER_FAIL_RESPONSE =
  "ON_LOCKER_LOCK_LISTENER_FAIL_RESPONSE"
export const CLEAR_RECENTLY_CLOSED_LOCKER_DOOR =
  "CLEAR_RECENTLY_CLOSED_LOCKER_DOOR"
export const RESET_CELLS_ERRORS_OPEN_ATTEMPS = "RESET_CELLS_ERRORS_OPEN_ATTEMPS"
export const ON_GET_OPENED_LOCKERS_STATUS_RESPONSE =
  "ON_GET_OPENED_LOCKERS_STATUS_RESPONSE"
export const SET_LOCKER_OCCUPIED = "SET_LOCKER_OCCUPIED"

// PAYMENT ACTIONS
export const ON_PAYMENT_REQUEST_START = "ON_PAYMENT_REQUEST_START"
export const ON_PAYMENT_SUCCESS = "ON_PAYMENT_SUCCESS"
export const ON_PAYMENT_FAIL = "ON_PAYMENT_FAIL"
export const ON_PAYMENT_NETWORK_FAIL = "ON_PAYMENT_NETWORK_FAIL"
export const RESET_PAYMENT_TO_TRY_AGAIN = "RESET_PAYMENT_TO_TRY_AGAIN"

// USER SIGN IN ACTIONS
export const ON_USER_SIGN_IN_REQUEST = "ON_USER_SIGN_IN_REQUEST"
export const ON_USER_SIGN_IN_SUCCESS = "ON_USER_SIGN_IN_SUCCESS"
export const ON_DRIVER_SIGN_IN_SUCCESS = "ON_DRIVER_SIGN_IN_SUCCESS"
export const ON_DRIVER_SIGN_IN_FAIL = "ON_DRIVER_SIGN_IN_FAIL"
export const ON_DRIVER_SIGN_IN_NOT_ALLOWED = "ON_DRIVER_SIGN_IN_NOT_ALLOWED"
export const ON_DRIVER_SIGN_IN_REQUEST = "ON_DRIVER_SIGN_IN_REQUEST"
export const SIGN_IN_WITH_OTP_FAIL = "SIGN_IN_WITH_OTP_FAIL"
export const CLEAR_ERR_STATUS = "CLEAR_ERR_STATUS"

export const ON_USER_SIGN_IN_FAIL = "ON_USER_SIGN_IN_FAIL"
export const ON_USER_SIGN_OUT = "ON_USER_SIGN_OUT"
export const USER_MOBILE_ALREADY_TAKEN_REQUEST =
  "USER_MOBILE_ALREADY_TAKEN_REQUEST"
export const USER_MOBILE_ALREADY_TAKEN_TRUE = "USER_MOBILE_ALREADY_TAKEN_TRUE"
export const USER_MOBILE_ALREADY_TAKEN_FALSE = "USER_MOBILE_ALREADY_TAKEN_FALSE"
export const USER_OTP_MOBILE_ALREADY_EXIST = "USER_OTP_MOBILE_ALREADY_EXIST"
export const GET_IS_USER_HAD_ORDER_BEFORE_SUCCESS =
  "GET_IS_USER_HAD_ORDER_BEFORE_SUCCESS"
export const GET_IS_USER_HAD_ORDER_BEFORE_FAIL =
  "GET_IS_USER_HAD_ORDER_BEFORE_FAIL"
export const SET_CC_SHURED = "SET_CC_SHURED"
export const RESET_CC_SHURED = "RESET_CC_SHURED"

// USER REGISTRATION ACTIONS
export const USERS_REGISTER_STEP_1_DATA_SUBMIT =
  "USERS_REGISTER_STEP_1_DATA_SUBMIT"
export const USERS_REGISTER_REQUEST_SUCCESS = "USERS_REGISTER_REQUEST_SUCCESS"
export const USERS_REGISTER_REQUEST_FAILURE = "USERS_REGISTER_REQUEST_FAILURE"
export const FETCH_ALL_STATIONS_DATA_SUCCESS = "FETCH_ALL_STATIONS_DATA_SUCCESS"
export const USERS_REGISTER_SET_PWD = "USERS_REGISTER_SET_PWD"
export const ON_REGISTRATION_VERIFY_SUCCESS = "ON_REGISTRATION_VERIFY_SUCCESS"
export const ON_REGISTRATION_VERIFY_FAILED = "ON_REGISTRATION_VERIFY_FAILED"
export const CLEAR_REGISTRATION_DATA = "CLEAR_REGISTRATION_DATA"
export const USERS_REGISTER_STEP_2_DATA_SUBMIT =
  "USERS_REGISTER_STEP_2_DATA_SUBMIT"

// DRIVER LOADING: READY ORDER ACTIONS
export const ON_READY_ORDERS_SUCCESS = "ON_READY_ORDERS_SUCCESS"
export const ON_READY_ORDERS_FAIL = "ON_READY_ORDERS_FAIL"
export const SET_NEW_CREATED_ORDER_ITEMS = "SET_NEW_CREATED_ORDER_ITEMS"
export const SET_NEW_CREATED_ORDER = "SET_NEW_CREATED_ORDER"

// LOCKER CELLS ACTIONS
export const FETCH_ALL_LOCKERS_STATUSES_SUCCESS =
  "FETCH_ALL_LOCKERS_STATUSES_SUCCESS"
export const FETCH_ALL_LOCKERS_STATUSES_FAIL = "FETCH_ALL_LOCKERS_STATUSES_FAIL"
export const FETCH_ALL_LOCKERS_LAYOUT_SUCCESS =
  "FETCH_ALL_LOCKERS_LAYOUT_SUCCESS"
export const FIND_NEXT_AVAILABLE_CELL = "FIND_NEXT_AVAILABLE_CELL"
export const ON_DRIVER_ORDER_COLLECTION_REQ_SUCCESS =
  "ON_DRIVER_ORDER_COLLECTION_REQ_SUCCESS"
export const ON_DRIVER_ORDER_COLLECTION_REQ_FAIL =
  "ON_DRIVER_ORDER_COLLECTION_REQ_FAIL"

export const ON_DRIVER_RETURNES_CLEAN_ORDER_REQ_SUCCESS =
  "ON_DRIVER_RETURNES_CLEAN_ORDER_REQ_SUCCESS"
export const ON_DRIVER_RETURNES_CLEAN_ORDER_REQ_FAIL =
  "ON_DRIVER_RETURNES_CLEAN_ORDER_REQ_FAIL"
export const ON_DRIVER_RETURNES_CLEAN_ORDER_CANCEL_SUCCESS =
  "ON_DRIVER_RETURNES_CLEAN_ORDER_CANCEL_SUCCESS"
export const ON_DRIVER_RETURNES_CLEAN_ORDER_CANCEL_FAIL =
  "ON_DRIVER_RETURNES_CLEAN_ORDER_CANCEL_FAIL"
export const ON_CLOSE_ORDER_REQ_SUCCESS = "ON_CLOSE_ORDER_REQ_SUCCESS"
export const ON_CLOSE_ORDER_REQ_FAIL = "ON_CLOSE_ORDER_REQ_FAIL"
export const SET_CHOSEN_CELL = "SET_CHOSEN_CELL"
export const CLEAN_PUT_TO_LOCKER_ERROR = "CLEAN_PUT_TO_LOCKER_ERROR"
export const CLEAN_CANCEL_PUT_TO_LOCKER_ERROR =
  "CLEAN_CANCEL_PUT_TO_LOCKER_ERROR"
export const ON_START_ORDER_LOADING = "ON_START_ORDER_LOADING"
export const ON_CANCEL_ORDER_LOADING = "ON_CANCEL_ORDER_LOADING"

export const ON_CUSTOMER_RETURNES_ORDER_CANCEL_SUCCESS =
  "ON_CUSTOMER_RETURNES_ORDER_CANCEL_SUCCESS"
export const ON_CUSTOMER_RETURNES_ORDER_CANCEL_FAIL =
  "ON_CUSTOMER_RETURNES_ORDER_CANCEL_FAIL"

// BG SERVICE ACTIONS
export const READ_STATION_ID_FROM_BG_SERVICE_SUCCESS =
  "READ_STATION_ID_FROM_BG_SERVICE_SUCCESS"
export const GET_STATION_NUMBER = "GET_STATION_NUMBER"
export const READ_STATION_ID_FROM_BG_SERVICE_FAIL =
  "READ_STATION_ID_FROM_BG_SERVICE_FAIL"
export const BG_SERVICE_ALIVE_SUCCESS = "BG_SERVICE_ALIVE_SUCCESS"
export const BG_SERVICE_ALIVE_FAIL = "BG_SERVICE_ALIVE_FAIL"

// THANK YOU SCREEN
export const THANK_YOU_SUB_TITLE_SET = "THANK_YOU_SUB_TITLE_SET"

// RECREATE USER PASSWORD
export const ON_RECREATE_USER_PASSWORD_REQUEST = `RECREATE_USER_PASSWORD_REQUEST`
export const ON_RECREATE_USER_PASSWORD_SUCCESS = `RECREATE_USER_PASSWORD_SUCCESS`
export const ON_RECREATE_USER_PASSWORD_FAIL = `RECREATE_USER_PASSWORD_FAIL`

// UPDATE USER PASSWORD
export const ON_UPDATE_USER_PASSWORD_REQUEST = `ON_UPDATE_USER_PASSWORD_REQUEST`
export const ON_UPDATE_USER_PASSWORD_SUCCESS = `ON_UPDATE_USER_PASSWORD_SUCCESS`
export const ON_UPDATE_USER_PASSWORD_FAIL = `ON_UPDATE_USER_PASSWORD_FAIL`
export const RESSET_UPDATE_USER_PASSWORD_FLOW = `RESSET_UPDATE_USER_PASSWORD_FLOW`

// TRANSLATIONS
export const ON_TRANSLATION_CHANGE = `ON_TRANSLATION_CHANGE`

// PRICE LIST ACTIONS
export const PRICE_LIST_FETCH_SUCCESS = `PRICE_LIST_FETCH_SUCCESS`
export const DYNAMIC_ITEMS_FETCH_SUCCESS = `DYNAMIC_ITEMS_FETCH_SUCCESS`

// TOKEN SAVE IN SERVER ACTIONS
export const ON_PELE_TOKEN_SAVED_IN_SERVER_REQUEST =
  "ON_PELE_TOKEN_SAVED_IN_SERVER_REQUEST"
export const ON_PELE_TOKEN_SAVED_IN_SERVER_SUCCESS =
  "ON_PELE_TOKEN_SAVED_IN_SERVER_SUCCESS"
export const ON_PELE_TOKEN_SAVED_IN_SERVER_FAIL =
  "ON_PELE_TOKEN_SAVED_IN_SERVER_FAIL"
export const ON_PELE_TOKEN_SAVED_IN_RETRY = "ON_PELE_TOKEN_SAVED_IN_RETRY"

// STATION LOGIN TYPES ACTIONS
export const FETCH_STATION_SERVICE_TYPES_SUCCESS =
  "FETCH_STATION_SERVICE_TYPES_SUCCESS"
export const FETCH_STATION_SERVICE_TYPES_FAIL =
  "FETCH_STATION_SERVICE_TYPES_FAIL"

//DELIVERY ACTIONS
export const SET_LOADING_TO_FETCH_ORDER_BY_DELIVERY_CODE =
  "SET_LOADING_TO_FETCH_ORDER_BY_DELIVERY_CODE"
export const FETCH_ORDER_BY_DELIVERY_CODE_SUCCESS =
  "FETCH_ORDER_BY_DELIVERY_CODE_SUCCESS"
export const FETCH_ORDER_BY_DELIVERY_CODE_FAIL =
  "FETCH_ORDER_BY_DELIVERY_CODE_FAIL"
export const RESET_DELIVERY_REDUCER = "RESET_DELIVERY_REDUCER"

//RESTORE DELIVERY CODE ACTIONS
export const SET_LOADING_RESTORE_DELIVERY_CODE =
  "SET_LOADING_RESTORE_DELIVERY_CODE"
export const FETCH_RESTORE_DELIVERY_CODE_SUCCESS =
  "FETCH_RESTORE_DELIVERY_CODE_SUCCESS"
export const FETCH_RESTORE_DELIVERY_CODE_FAIL =
  "FETCH_RESTORE_DELIVERY_CODE_FAIL"

//COUPONS ACTIONS
export const RESET_COUPONS_REDUCER = "RESET_COUPONS_REDUCER"
export const SET_LOADING_COUPON = "SET_LOADING_COUPON"
export const FETCH_COUPON_BY_NAME_SUCCESS = "FETCH_COUPON_BY_NAME_SUCCESS"
export const FETCH_COUPON_BY_NAME_FAIL = "FETCH_COUPON_BY_NAME_FAIL"

//ORDER TYPES ACTIONS
export const ON_GET_ORDER_TYPES_SUCCESS = "ON_GET_ORDER_TYPES_SUCCESS"
export const ON_GET_ORDER_TYPES_FAILURE = "ON_GET_ORDER_TYPES_FAILURE"
export const ON_LOADING = "ON_LOADING"
export const COMPLETE_USER_DATA_SUCCESS = "COMPLETE_USER_DATA_SUCCESS"
export const COMPLETE_USER_DATA_FAIL = "COMPLETE_USER_DATA_FAIL"
export const COMPLETE_USER_DATA_RESET = "COMPLETE_USER_DATA_RESET"
export const ON_BASE_ERROR = "ON_BASE_ERROR"

//NEW ORDER ACTIONS
export const ON_REQUEST_CREATE_NEW_ORDER = "ON_REQUEST_CREATE_NEW_ORDER"
export const ON_CREATE_NEW_ORDER_SUCCESS = "ON_CREATE_NEW_ORDER_SUCCESS"
export const ON_CREATE_NEW_ORDER_FAIL = "ON_CREATE_NEW_ORDER_FAIL"
export const ON_CREATE_NEW_ORDER_CLEAR = "ON_CREATE_NEW_ORDER_CLEAR"

//VISITING SCREENS ACTIONS
export const SET_PAGE_AS_VISITED = "SET_PAGE_AS_VISITED"

//FLOW ACTIONS
export const SET_ACTIVE_FLOW = "SET_ACTIVE_FLOW"
export const SET_FLOW_ACTIVE_PAGE = "SET_FLOW_ACTIVE_PAGE"

//LOCKER TO LOCKER ACTIONS

export const FETCH_AVAILABLE_LOCKERS_STATUSES_SUCCESS =
  "FETCH_AVAILABLE_LOCKERS_STATUSES_SUCCESS"
export const FETCH_AVAILABLE_LOCKERS_STATUSES_FAIL =
  "FETCH_AVAILABLE_LOCKERS_STATUSES_FAIL"

export const SET_LOCKER_TO_LOCKER_DELIVERY_ORDER =
  "SET_LOCKER_TO_LOCKER_DELIVERY_ORDER"
export const FETCH_LOCKER_TO_LOCKER_DELIVERY_STATION_SUCCESS =
  "FETCH_LOCKER_TO_LOCKER_DELIVERY_STATION_SUCCESS"
export const FETCH_LOCKER_TO_LOCKER_DELIVERY_STATION_FAIL =
  "FETCH_LOCKER_TO_LOCKER_DELIVERY_STATION_FAIL"
export const ON_CREATE_LOCKER_TO_LOCKER_DELIVERY_ORDER_SUCCESS =
  "ON_CREATE_LOCKER_TO_LOCKER_DELIVERY_ORDER_SUCCESS"
export const ON_CREATE_LOCKER_TO_LOCKER_DELIVERY_ORDER_FAIL =
  "ON_CREATE_LOCKER_TO_LOCKER_DELIVERY_ORDER_FAIL"
export const ON_LOCKER_TO_LOCKER_DELIVERY_LOADING =
  "ON_LOCKER_TO_LOCKER_DELIVERY_LOADING"

export const ON_GET_ORDER_FOR_PAY_SUCCESS = "ON_GET_ORDER_FOR_PAY_SUCCESS"
export const ON_GET_ORDER_FOR_PAY_FAIL = "ON_GET_ORDER_FOR_PAY_FAIL"

export const ON_CONFIRM_CREATE_ORDER_SUCCESS = "ON_CONFIRM_CREATE_ORDER_SUCCESS"
export const ON_CONFIRM_CREATE_ORDER_FAIL = "ON_CONFIRM_CREATE_ORDER_FAIL"
export const SET_LOCKER_TO_LOCKER_DELIVERY_PAYED_ORDER =
  "SET_LOCKER_TO_LOCKER_DELIVERY_PAYED_ORDER"
export const ON_GET_LOCKER_TO_LOCKER_DELIVERY_ORDERS_REQUESTS_SUCCESS =
  "ON_GET_LOCKER_TO_LOCKER_DELIVERY_ORDERS_REQUESTS_SUCCESS"

export const ON_GET_ORDERS_REQUESTS_SUCCESS = "ON_GET_ORDERS_REQUESTS_SUCCESS"
export const ON_GET_ORDERS_REQUESTS_FAIL = "ON_GET_ORDERS_REQUESTS_FAIL"

export const ON_CLEAR_ORDER_FOR_CONFIRM_CREATE =
  "ON_CLEAR_ORDER_FOR_CONFIRM_CREATE"
export const ON_ADD_COUPON_TO_ORDER_SUCCESS = "ON_ADD_COUPON_TO_ORDER_SUCCESS"
export const ON_ADD_COUPON_TO_ORDER_FAIL = "ON_ADD_COUPON_TO_ORDER_FAIL"
export const ON_CLEAR_LTOL_REDUCER = "ON_CLEAR_LTOL_REDUCER"
export const SIGN_IN_WITH_OTP = "SIGN_IN_WITH_OTP"

//SHIPING_PACKAGE
export const SET_LOADING_SHIPING_PACKAGE = "SET_LOADING_SHIPING_PACKAGE"
export const SET_SHIPING_PACKAGE_USER = "SET_SHIPING_PACKAGE_USER"
export const SET_SHIPING_PACKAGE_ORDER = "SET_SHIPING_PACKAGE_ORDER"

export const CREATE_SHIPING_PACKAGE_ORDER_SUCCESS =
  "CREATE_SHIPING_PACKAGE_ORDER_SUCCESS"
export const CREATE_SHIPING_PACKAGE_ORDER_FAIL =
  "CREATE_SHIPING_PACKAGE_ORDER_FAIL"

export const VALIDATE_SHIPING_PACKAGE_NUMBER_SUCCESS =
  "VALIDATE_SHIPING_PACKAGE_NUMBER_SUCCESS"
export const CLEAR_VALIDATION_FIELDS = "CLEAR_VALIDATION_FIELDS"
export const VALIDATE_SHIPING_PACKAGE_NUMBER_FAIL =
  "VALIDATE_SHIPING_PACKAGE_NUMBER_FAIL"

export const CLEAR_SHIPING_PACKAGE = "CLEAR_SHIPING_PACKAGE"

export const CREATE_RETURN_SHIPING_PACKAGE_ORDER_SUCCESS =
  "CREATE_RETURN_SHIPING_PACKAGE_ORDER_SUCCESS"
export const CREATE_RETURN_SHIPING_PACKAGE_ORDER_FAIL =
  "CREATE_RETURN_SHIPING_PACKAGE_ORDER_FAIL"

//ERROR NETWORK
export const ON_ERROR_NETWORK = "ON_ERROR_NETWORK"
export const ON_CLEAR_ERROR_NETWORK = "ON_CLEAR_ERROR_NETWORK"
export const ON_ERROR_TIMEOUT = "ON_ERROR_TIMEOUT"

//PING API
export const ALIVE_API_SUCCESS = "ALIVE_API_SUCCESS"
export const ALIVE_API_FAIL = "ALIVE_API_FAIL"

// STATION TASKS
export const ON_STATION_TASKS_LOADING = "ON_STATION_TASKS_LOADING"
export const ON_GET_STATION_TASKS_SUCCESS = "ON_GET_STATION_TASKS_SUCCESS"
export const ON_GET_STATION_TASKS_FAIL = "ON_GET_STATION_TASKS_FAIL"
export const ON_TASK_COMPLETED_LOADING = "ON_TASK_COMPLETED_LOADING"
export const ON_TASK_COMPLETED_SUCCESS = "ON_TASK_COMPLETED_SUCCESS"
export const ON_TASK_COMPLETED_FAIL = "ON_TASK_COMPLETED_FAIL"
export const CLEAR_TASK_COMPLETED_STATE = "CLEAR_TASK_COMPLETED_STATE"

// CAMERA
export const ON_TAKE_PICTURE_SUCCESS = "ON_TAKE_PICTURE_SUCCESS"
export const ON_TAKE_PICTURE_FAILURE = "ON_TAKE_PICTURE_FAILURE"
