import { React } from 'react';

export const newlineText = (text) => {
    if(text===undefined || text === null || text.length <=1){
        return text;
    }
    let lines = text.split('\n')
    if(lines.length <=1){
        return text;
    }
    return lines.map(str => {return ( <p>{str}</p>)});
 
 }