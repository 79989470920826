import {
  ON_TAKE_PICTURE_SUCCESS,
  ON_TAKE_PICTURE_FAILURE,
} from "../actions/types"

const INIT_STATE = {
  takePictureSuccess: false,
  takePictureFail: false,
}

const CameraReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_TAKE_PICTURE_SUCCESS:
      return {
        takePictureSuccess: true,
        takePictureFail: false,
      }
    case ON_TAKE_PICTURE_FAILURE:
      return {
        takePictureSuccess: false,
        takePictureFail: true,
      }

    default:
      return state
  }
}

export default CameraReducer
