import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { FixedFooter, FixedHeader, LockerCells } from "../../../../reduxified"
import ErrorInfoModal from "../../../../Modals/ErrorInfoModal"
import LoadingInfoModal from "../../../../Modals/LoadingInfoModal"
import WaitingForLockerLock from "../../../../Modals/WaitingForLockerLock"
import {
  LogoutUser,
  reportFBEvent,
  logScreenEnterance,
  setLockerToLockerDeliveryOrder,
  confirmCreateOrder,
  openAndListenToSpecificLockerLock,
  resetLockersErrorsAttemptsOpened,
  setThankYouSubTitle,
  getOrderForPay,
  setLockerToLockerDeliveryOrderLoading,
  clearLtoLReducer,
  resetPaymentToTryAgain,
} from "../../../../../actions"
import "./style.css"
import YesNoInfoModal from "../../../../Modals/YesNoInfoModal"

class SelectLocker extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      isClickWaiting: false,
      isOpenedDoor: false,
      showContinueModal: false,
    }
    this.state = this.INIT_STATE
  }

  componentDidMount() {
    const { authenticated } = this.props
    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }
    this.props.logScreenEnterance("LOCKER_TO_LOCKER_DELIVERY SELECT_LOCKER")
  }

  componentWillReceiveProps(newProps) {
    const { authenticated, lockersData } = newProps

    if (!authenticated) {
      //add report
      this.props.reportFBEvent(
        "LOCKER_TO_LOCKER SELECT_LOCKER",
        "Not authenticated. Redirect to homepage."
      )
      this.props.LogoutUser()
      this.props.history.push("")
    }

    const {
      orderWasLoaded,
      openingErrorOccurred,
      openingDoorSuccess,
      payedOrder,
      createdOrder,
      isOrderConfirmed,
      lockersWithOpenStatusFromHw,
    } = newProps

    if (payedOrder == null && !isOrderConfirmed) {
      this.props.reportFBEvent(
        "LockerToLocker SelectLocker",
        "The payedOrder is null. "
      )
      if (createdOrder) {
        this.props.getOrderForPay(createdOrder.orderId)
      }
      return
    }

    if (openingDoorSuccess) {
      if (this.state.isOpenedDoor) {
        return
      }
      this.setState({ isOpenedDoor: true })
      this.props.setLockerToLockerDeliveryOrderLoading()
      this.props.confirmCreateOrder({
        ...payedOrder,
        optionalWrongDoor: lockersWithOpenStatusFromHw.toString(),
      })
      return
    }

    if (orderWasLoaded) {
      const haveEmpty = lockersData.filter(
        (locker) => locker.lockerStatus === 3 && !locker.order
      ).length

      if (haveEmpty > 1) {
        this.setState({ showContinueModal: true })
        return
      } else {
        this.props.setThankYouSubTitle(newProps.see_you_again)
        this.props.history.push("/ThankYouScreen")
        return
      }
    }

    if (openingErrorOccurred && !this.state.isOpenedDoor) {
      this.setState({ showDoorOpenErrorModal: true })
    }
  }

  checkIsWaiting() {
    if (this.state.isCellClickWaiting) {
      return true
    }
    return false
  }

  isWaiting(wait) {
    if (wait && this.state.isCellClickWaiting) {
      return true
    }

    let { timeO } = this.state

    timeO = wait
      ? setTimeout((num) => {
          this.isWaiting(num, false)
        }, 2000)
      : clearTimeout(timeO)

    this.setState({
      ...this.state,
      isCellClickWaiting: wait,
      timeO: timeO,
    })

    return wait
  }

  async onCellClick(cellNum, lockerData) {
    if (lockerData == undefined) {
      return
    }

    const { disabled } = lockerData
    if (disabled) {
      return
    }
    const { isLoading, isOrderConfirmed, stationId } = this.props
    if (isLoading || isOrderConfirmed) {
      return
    }

    if (this.checkIsWaiting()) return

    let { payedOrder, createdOrder } = this.props
    if (!payedOrder) {
      this.props.reportFBEvent(
        "LockerToLocker SelectLocker",
        "The payedOrder is null. "
      )
      if (!createdOrder) {
        this.props.reportFBEvent(
          "LockerToLocker SelectLocker",
          "The payedOrder and createdOrder is null. Redirect homepage."
        )
        this.props.LogoutUser()
        this.props.history.push("")
      }
      return
    }
    payedOrder.lockerNum = cellNum
    payedOrder.locationId = lockerData.lockerId
    const { stationPaymentVersion } = this.props
    await this.props.setLockerToLockerDeliveryOrder(payedOrder)
    await this.props.openAndListenToSpecificLockerLock(
      cellNum,
      undefined, //attempts
      stationId,
      undefined, //packageNumber
      2, //it's lockerStatus (2 means 'full', 3 means 'empty')
      stationPaymentVersion
    )
    this.isWaiting(cellNum, true)
  }

  onDoorOpenErrModalClose() {
    this.setState({
      showDoorOpenErrorModal: false,
      clickedLocker: null,
      isCellClickWaiting: false,
    })
    this.props.resetLockersErrorsAttemptsOpened()
  }

  render() {
    const {
      blinkers,
      errLockers,
      opening_locker_number,
      waitingForLockerLockTitle,
      pls_close_door,
      err_while_opening_door,
      try_another_locker_or_contact_support,
      close_text,
      please_wait,
      openingDoorSuccess,
      openAttemptsNum,
      dirRtl,
      payedOrder,
      choose_locker,
      want_to_place_another_order,
      yes_text,
      no_text,
      see_you_again,
      cancel_operation,
    } = this.props

    const { showDoorOpenErrorModal, showContinueModal } = this.state

    let { lockerNum } = 11111
    if (
      payedOrder &&
      payedOrder.lockerNum != undefined &&
      payedOrder.lockerNum != null
    ) {
      lockerNum = payedOrder.lockerNum
    }

    return (
      <>
        <div className="ls-wrapper ">
          <FixedHeader buttonsFlag="unlockLockersScreen" />
          <div className="rent-locker-title">{choose_locker}</div>

          <div className="ddocs-inner-wrapper">
            <LockerCells
              onCellClick={this.onCellClick.bind(this)}
              blinkers={blinkers}
              errLockers={errLockers}
              hideInfo={true}
              lockerToLockerDelivery={true}
            />
          </div>
          <FixedFooter history={this.props.history} />
        </div>
        <YesNoInfoModal
          dirRtl={dirRtl}
          show={showContinueModal}
          title={want_to_place_another_order}
          onModalOkClick={async () => {
            await this.props.clearLtoLReducer()
            await this.props.resetPaymentToTryAgain()
            this.props.history.push("/LockerToLockerDeliveryScreen/Menu")
          }}
          onModalCancelClick={() => {
            this.setState({ showContinueModal: false })
            this.props.setThankYouSubTitle(see_you_again)
            this.props.history.push("/ThankYouScreen")
          }}
          okTransl={yes_text}
          cancelTransl={no_text}
        />
        <LoadingInfoModal
          dirRtl={dirRtl}
          show={openAttemptsNum > 0 && !showDoorOpenErrorModal}
          title={opening_locker_number.replace("{XYZ}", lockerNum)}
          text={please_wait}
        />

        <WaitingForLockerLock
          dirRtl={dirRtl}
          show={openingDoorSuccess}
          title={waitingForLockerLockTitle.replace("{XYZ}", lockerNum)}
          subTitle={pls_close_door}
          cancelText={cancel_operation}
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showDoorOpenErrorModal}
          title={err_while_opening_door}
          text={try_another_locker_or_contact_support.replace(
            "{XYZ}",
            lockerNum
          )}
          onModalCloseClick={() => this.onDoorOpenErrModalClose()}
          closeTrnsl={close_text}
        />
      </>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  lockersCells,
  translate,
  bgService,
  lockerToLockerDelivery,
  stationServiceTypes,
}) => {
  const { stationPaymentVersion } = stationServiceTypes

  const { authenticated, role, userId } = userLogin
  const {
    openedLockersArr,
    errorLockersArr,
    openingAttempsMap,
    recentClosedLockersArr,
    brokenLockersNumArr,
    lockersWithOpenStatusFromHw,
    lockersData,
  } = lockersCells
  const {
    dirRtl,
    driver_menu_lockers_cells_opening,
    driver_lockers_cells_opening_subtitle,
    please_wait,
    opening_locker_number,
    on_order_collection_finish,
    pls_close_door,
    try_another_locker_or_contact_support,
    err_while_opening_door,
    close_text,
    choose_locker,
    yes_text,
    no_text,
    see_you_again,
    want_to_place_another_order,
    cancel_operation,
  } = translate

  const waitingForLockerLockTitle = `${opening_locker_number}. ${on_order_collection_finish}`

  const { stationId } = bgService
  const { payedOrder, createdOrder, isLoading, isOrderConfirmed } =
    lockerToLockerDelivery

  const { lockerNum } = payedOrder || {}
  const openingDoorSuccess = openedLockersArr.includes(lockerNum)
  const openingErrorOccurred = errorLockersArr.includes(lockerNum)
  const orderWasLoaded = recentClosedLockersArr.includes(lockerNum)
  const openAttemptsNum = openingAttempsMap[lockerNum] || 0
  const blinkers = !openingErrorOccurred ? { [lockerNum]: lockerNum } : {}
  const errLockers = openingErrorOccurred ? { [lockerNum]: lockerNum } : {}

  return {
    stationPaymentVersion,
    authenticated,
    role,
    userId,
    blinkers,
    errLockers,
    openingDoorSuccess,
    openingErrorOccurred,
    orderWasLoaded,
    openAttemptsNum,
    driver_menu_lockers_cells_opening,
    driver_lockers_cells_opening_subtitle,
    opening_locker_number,
    stationId,
    payedOrder,
    createdOrder,
    brokenLockersNumArr,
    lockersWithOpenStatusFromHw,
    dirRtl,
    please_wait,
    waitingForLockerLockTitle,
    pls_close_door,
    try_another_locker_or_contact_support,
    err_while_opening_door,
    close_text,
    isLoading,
    isOrderConfirmed,
    choose_locker,
    yes_text,
    no_text,
    see_you_again,
    want_to_place_another_order,
    lockersData,
    cancel_operation,
  }
}

export default connect(mapStateToProps, {
  LogoutUser,
  reportFBEvent,
  logScreenEnterance,
  setLockerToLockerDeliveryOrder,
  confirmCreateOrder,
  openAndListenToSpecificLockerLock,
  resetLockersErrorsAttemptsOpened,
  setThankYouSubTitle,
  getOrderForPay,
  setLockerToLockerDeliveryOrderLoading,
  clearLtoLReducer,
  resetPaymentToTryAgain,
})(withRouter(SelectLocker))
