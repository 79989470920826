import React from "react"
import Modal from "react-modal"
import { OrangeButton } from "../../common"
import "./style.scss"

const YesNoContinueExistModal = ({
  show,
  title,
  text,
  onModalOkClick,
  onModalCancelClick,
  okTransl,
  cancelTransl,
  dirRtl,
  order,
  transl,
}) => {
  return (
    <Modal
      isOpen={show}
      onRequestClose={onModalCancelClick}
      className="react-modal-content"
      overlayClassName="modal-overlay returns-continue-request-modal"
    >
      <div className="cancel-order-title" dir={dirRtl ? "rtl" : "ltr"}>
        {title}
      </div>
      <div className="cancel-order-title" dir={dirRtl ? "rtl" : "ltr"}>
        {text}
      </div>
      <div className="request-details" dir={dirRtl ? "rtl" : "ltr"}>
        <div className="row">
          <div className="label">{transl.package_number}</div>
        </div>

        <div className="package-number">{order?.packageNumber}</div>

        <div className="row">
          <div className="label">{transl.mobile_input_hint}</div>
          <div className="details">{order?.mobilePhone}</div>
        </div>
        <div className="row">
          <div className="label">{transl.first_name_input_hint}</div>
          <div className="details">{order?.firstName}</div>
        </div>
        <div className="row">
          <div className="label">{transl.last_name_input_hint}</div>
          <div className="details">{order?.lastName}</div>
        </div>
      </div>
      <div className="yninmo-yes-no-wrapper" dir={dirRtl ? "rtl" : "ltr"}>
        <OrangeButton
          onClick={(e) => onModalOkClick()}
          extraClass="cancel-order-button"
        >
          {okTransl}
        </OrangeButton>
        <OrangeButton
          onClick={(e) => onModalCancelClick()}
          extraClass="cancel-order-button"
        >
          {cancelTransl}
        </OrangeButton>
      </div>
    </Modal>
  )
}

export default YesNoContinueExistModal
