import React, { Component } from "react"
import { connect } from "react-redux"
import {
  changeDisplayedLanguage,
  logScreenEnterance,
  onUserSignInClick,
  onDriverSignInClick,
  readBgServiceConfiguration,
  readStationIdFromBgService,
  reportSuspiciousBrokenLockers,
  resetAllReducers,
  clearLoginErrStatus,
  takePicture,
} from "../../../../actions"
import { AppVersion } from "../../../../utils/Env"
import { isProdEnv } from "../../../../utils"
import { DisEnbPendBtn, InputFiled } from "../../../common"
import ButtonBackground from "../../../../assets/svg/bg_login_button.svg"
import ManCellphone from "../../../../assets/svg/man_cell_phone.svg"
import WomanCellphone from "../../../../assets/svg/woman_cell_phone.svg"
import WhiteQRCode from "../../../../assets/svg/white_qr_code.svg"
import VirtualKeyboard from "../../../common/VirtualKeyboard"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"
import { ENABLE_CAMERA } from "../../../../utils/Env"
import { FixedFooter, FixedHeader } from "../../../reduxified"
import "./style.css"

class LoginScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mobile: "",
      pwd: "",

      mobileRef: null,
      pwdRef: null,

      activeInput: null,
      activeInputName: null,

      isSubmitBtnDisabled: false,
      mobileErr: false,
      pwdErr: false,
      errorsTimeout: null,

      showErrorForCustomer: false,
      showErrorModal: false,
      isPasswordResetModalShown: false,

      runValidationsTimeOut: null,
      openedDoorModalCloseClicked: false,
      inputPasswordType: "password",
      showErrForHFD: false,
    }
  }

  componentDidMount() {
    if (!this.props.stationId || this.props.stationId.length < 1) {
      this.props.history.push("/")
    }

    this.props.resetAllReducers()
    this.props.readStationIdFromBgService()
    this.props.readBgServiceConfiguration()

    const hideLoginData = isProdEnv() || this.props.isDeliverySimulationStation
    this.setState({
      mobile: hideLoginData ? "" : "0000000002",
      pwd: hideLoginData ? "" : "123456",
    })
    this.props.logScreenEnterance("LOGIN_SCREEN")
  }

  componentWillUnmount() {
    let { runValidationsTimeOut, errorsTimeout } = this.state
    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }
    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }
  }

  async componentWillReceiveProps(newProps) {
    const {
      authenticated,
      errTitle,
      errText,
      isPasswordTemporary,
      role,
      stationId,
      userId,
    } = newProps
    newProps.errStatus === 403 && this.setState({ showErrForHFD: true })

    if (authenticated) {
      if (role === "Customer") {
        this.setState({ showErrorForCustomer: true })
      }
      if (isPasswordTemporary) {
        this.setState({ isPasswordResetModalShown: true })
      } else if (role !== "Customer") {
        const data = {
          stationId: stationId,
          eventType: "driverLogin",
          userId: userId,
        }
        if (ENABLE_CAMERA) {
          this.props.takePicture(data)
        }
        this.props.history.push("DriverMenuScreen")
      }
    }

    //! TODOD- RESET THE ERR TITLE AND TEXT WHEN STARTING NEW REQ!
    const {
      errTitle: oldErrTitle,
      errText: oldErrText,
      maxLockerNumber: oldMaxLockerNum,
    } = this.props

    if (oldErrTitle !== errTitle || oldErrText !== errText) {
      setTimeout(
        () =>
          this.setState({
            showErrorModal: true,
          }),
        250
      )
    }
  }

  onKeyboardTextChange(text) {
    const { activeInputName, errorsTimeout } = this.state

    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }

    this.setState({
      [activeInputName]: text,
      mobileErr: false,
      pwdErr: false,
      errorsTimeout: null,
    })

    this.runValidationsWithTimoutDebounce()
  }

  onRefReady(refName, ref) {
    if (!this.state[refName]) this.setState({ [refName]: ref })
  }

  onInputFocusChange(refName, isFocused) {
    if (isFocused) {
      this.setState({
        activeInput: this.state[refName],
        activeInputName: refName.replace("Ref", ""),
      })
    } else {
      if (this.state.activeInput === this.state[refName])
        this.setState({ activeInput: null })
    }
  }

  isMobileNumValid(mobile) {
    if (!mobile || mobile.length === 0) {
      return false
    }

    return mobile.length === 10
  }

  isPwdValid(pwd) {
    if (!pwd || pwd.length === 0) {
      return false
    }

    return pwd.length > 3
  }

  runValidations() {
    const { mobile, pwd } = this.state

    const isMobileNumValid = this.isMobileNumValid(mobile)
    const isPwdValid = this.isPwdValid(pwd)

    const isEmptyMobile = mobile.length < 1
    const isEmptyPwd = pwd.length < 1

    const isSubmitBtnDisabled = !isMobileNumValid || !isPwdValid
    const mobileErr = !isMobileNumValid && !isEmptyMobile
    const pwdErr = !isPwdValid && !isEmptyPwd

    if (!isSubmitBtnDisabled) {
      this.setState({
        isSubmitBtnDisabled,
        mobileErr,
        pwdErr,
        runValidationsTimeOut: null,
      })
    } else {
      const errorsTimeout = setTimeout(
        () =>
          this.setState({
            isSubmitBtnDisabled,
            mobileErr,
            pwdErr,
            runValidationsTimeOut: null,
          }),
        800
      )

      this.setState({ errorsTimeout })
    }
  }

  onSignInClick() {
    const { mobile, pwd } = this.state
    const { stationId } = this.props
    this.props.history.location.state === "driversLogin"
      ? this.props.onDriverSignInClick(mobile, pwd, stationId)
      : this.props.onUserSignInClick(mobile, pwd)
  }

  onSignUpClick() {
    this.props.history.push("SignUpStep1Screen")
  }

  onForgotPasswordClick() {
    this.props.history.push("RecreatePasswordScreen")
  }

  onClosePasswordResetModal() {
    this.props.history.push(
      "PasswordUpdateScreen",
      this.props.history.location.state
    )
  }

  onInputFieldChange(inputName, val, evt) {
    evt.persist()
    this.setState({ [inputName]: val })
    this.runValidationsWithTimoutDebounce(inputName, val)
  }

  runValidationsWithTimoutDebounce() {
    let { runValidationsTimeOut } = this.state

    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }

    runValidationsTimeOut = setTimeout(() => this.runValidations(), 500)

    this.setState({ runValidationsTimeOut })
  }

  filterEnterAndSubmit(e) {
    e.persist()
    if (e.key === "Enter") {
      setTimeout(() => {
        const { isSubmitBtnDisabled } = this.state

        if (!isSubmitBtnDisabled) {
          this.onSignInClick()
        }
      }, 700)
    }
  }

  onLangChange(lang) {
    const { stationId } = this.props

    this.props.changeDisplayedLanguage(lang)
  }
  onViewPasswordClick() {
    const { inputPasswordType } = this.state
    if (inputPasswordType === "password") {
      this.setState({ inputPasswordType: "text" })
    } else {
      this.setState({ inputPasswordType: "password" })
    }
  }

  render() {
    const {
      mobile,
      pwd,
      activeInputName,
      isSubmitBtnDisabled,
      mobileErr,
      pwdErr,
      showErrorModal,
      isPasswordResetModalShown,
      showErrForHFD,
      showErrorForCustomer,
    } = this.state

    const {
      errTitle,
      errText,
      isLoadingRequest,
      dirRtl,
      cellphone_input_text_hint,
      password_input_text_hint,
      login_btn_text,
      pick_const_pwd_msg_title,
      pick_const_pwd_msg_body,
      close_text,
      login_courier,
      login_just_for_drivers,
      stationNumber,
      showDriverNoLoginPermission,
      driver_have_no_login_permission_to_route,
      please_contact_manager,
    } = this.props

    return (
      <div className="ls-wrapper">
        <div className="testing-version">{`v${AppVersion} Station #${stationNumber}`}</div>
        <FixedHeader
          title={login_courier}
          subTitle=""
          buttonsFlag="loginScreen"
        />

        <div className="done-id-and-inputs">
          <div className="buttons-login-screen">
            <div
              className="otp-doneid-btn"
              onClick={() => {
                this.props.history.push("/LoginWithOTP", "driversLogin")
              }}
            >
              <p className="otp-doneid-btn-txt" dir={dirRtl ? "rtl" : "lrt"}>
                כניסה בעזרת נייד וקוד
              </p>
              <div className="otp-doneid-btn-img">
                <img src={ButtonBackground} alt="otp_button" />
                <img
                  src={ManCellphone}
                  alt="ManCellphone"
                  className="otp-doneid-btn-man"
                />
                <p className="asterisks">****</p>
                <p
                  className="otp-doneid-btn-inner-txt"
                  dir={dirRtl ? "rtl" : "lrt"}
                >
                  לחצו כאן לכניסה באמצעות קבלת קוד לנייד
                </p>
              </div>
            </div>
            <div
              className="otp-doneid-btn"
              onClick={() =>
                this.props.history.push("/DoneLoginScreen", "driversLogin")
              }
            >
              <p className="otp-doneid-btn-txt" dir={dirRtl ? "rtl" : "lrt"}>
                {" "}
                כניסה מהירה בעזרת האפליקציה
              </p>
              <div className="otp-doneid-btn-img">
                <img src={ButtonBackground} alt="doneID_button" />
                <img
                  src={WomanCellphone}
                  alt="doneID_button"
                  className="otp-doneid-btn-woman"
                />
                <img
                  src={WhiteQRCode}
                  alt="doneID_button"
                  className="otp-doneid-btn-QR-code"
                />
                <p
                  className="otp-doneid-btn-inner-txt"
                  dir={dirRtl ? "rtl" : "lrt"}
                >
                  לחצו כאן לסריקת ה - DONE ID
                </p>
              </div>
            </div>
          </div>
          <div className="login-inputs-and-title">
            <div className="login-title" dir={dirRtl ? "rtl" : "ltr"}>
              {login_courier}
            </div>
            <div className="ls-frame-wrapper" dir={dirRtl ? "rtl" : "lrt"}>
              <div className="password-tel-wrapper">
                <div className="login-title"></div>
                <InputFiled
                  value={mobile}
                  onChange={(e) =>
                    this.onInputFieldChange("mobile", e.target.value, e)
                  }
                  placeholder={cellphone_input_text_hint}
                  onRefReady={(ref) => this.onRefReady("mobileRef", ref)}
                  onFocusChanged={(isFocused) =>
                    this.onInputFocusChange("mobileRef", isFocused)
                  }
                  displayErr={mobileErr}
                  onKeyPress={(e) => this.filterEnterAndSubmit(e)}
                  maxLength={10}
                  type="tel"
                  dirRtl={dirRtl}
                  step1
                  autoFocus
                />
                <InputFiled
                  value={pwd}
                  showEye
                  onChange={(e) =>
                    this.onInputFieldChange("pwd", e.target.value, e)
                  }
                  placeholder={password_input_text_hint}
                  type={this.state.inputPasswordType}
                  onRefReady={(ref) => this.onRefReady("pwdRef", ref)}
                  onFocusChanged={(isFocused) =>
                    this.onInputFocusChange("pwdRef", isFocused)
                  }
                  displayErr={pwdErr}
                  onViewPasswordClick={() => this.onViewPasswordClick()}
                  onKeyPress={(e) => this.filterEnterAndSubmit(e)}
                  dirRtl={dirRtl}
                  step1
                />
              </div>
              <DisEnbPendBtn
                onClick={(e) => this.onSignInClick()}
                isDisabled={isSubmitBtnDisabled}
                isPending={isLoadingRequest}
                extraClass="login-button"
              >
                {login_btn_text}
              </DisEnbPendBtn>
            </div>

            <VirtualKeyboard
              currentValue={this.state[activeInputName]}
              onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
              show={activeInputName}
              lang={activeInputName === "mobile" ? "mobile" : "en"}
              closeOnEnter
              driversLogin={activeInputName === "mobile"}
              blockLangSwitch={this.state.inputPasswordType}
            />
          </div>
        </div>
        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showErrorModal && errText && errTitle && !showErrForHFD}
          title={errTitle}
          text={errText}
          onModalCloseClick={() => this.setState({ showErrorModal: false })}
          closeTrnsl={close_text}
        />
        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showErrorForCustomer}
          text={login_just_for_drivers}
          onModalCloseClick={() =>
            this.setState({ showErrorForCustomer: false })
          }
          closeTrnsl={close_text}
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showDriverNoLoginPermission}
          text={
            driver_have_no_login_permission_to_route +
            "\n" +
            please_contact_manager
          }
          onModalCloseClick={() => this.props.clearLoginErrStatus()}
          closeTrnsl={close_text}
        />

        <ErrorInfoModal
          show={isPasswordResetModalShown}
          title={pick_const_pwd_msg_title}
          text={pick_const_pwd_msg_body}
          onModalCloseClick={() => this.onClosePasswordResetModal()}
          closeTrnsl={close_text}
        />
        <ErrorInfoModal
          dirRtl={dirRtl}
          show={this.state.showErrForHFD}
          title="נסה להיכנס עם סיסמה חד פעמית"
          onModalCloseClick={() =>
            this.setState({ showErrForHFD: false, showErrorModal: false })
          }
          closeTrnsl={close_text}
        />

        <FixedFooter history={this.props.history} hideBack={false} />
      </div>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  bgService,
  lockersCells,
  translate,
  stationServiceTypes,
}) => {
  const { stationId, stationNumber } = bgService
  const { maxLockerNumber, brokenLockersNumArr } = lockersCells
  const {
    authenticated,
    errTitle,
    errText,
    errStatus,
    isLoadingRequest,
    isPasswordTemporary,
    userId,
    role,
    isNeedAccessibility,
    showDriverNoLoginPermission,
  } = userLogin
  const {
    dirRtl,
    langName,
    welcome_title_text,
    cellphone_input_text_hint,
    register_btn_text,
    password_input_text_hint,
    login_btn_text,
    forgot_password_btn_text,
    password_for_delivery,
    pick_const_pwd_msg_title,
    pick_const_pwd_msg_body,
    close_text,
    back_btn_text,
    system_updgrade_err_msg,
    login_courier,
    login_just_for_drivers,
    driver_have_no_login_permission_to_route,
    please_contact_manager,
  } = translate

  const { isDeliverySimulationStation } = stationServiceTypes
  return {
    authenticated,
    errTitle: translate[errTitle],
    errText: translate[errText],
    errStatus,
    isLoadingRequest,
    isPasswordTemporary,
    userId,
    stationId,
    stationNumber,
    maxLockerNumber,
    brokenLockersNumArr,
    dirRtl,
    langName,
    welcome_title_text,
    cellphone_input_text_hint,
    password_input_text_hint,
    login_btn_text,
    forgot_password_btn_text,
    password_for_delivery,
    register_btn_text,
    pick_const_pwd_msg_title,
    pick_const_pwd_msg_body,
    back_btn_text,
    close_text,
    system_updgrade_err_msg,
    isDeliverySimulationStation,
    login_courier,
    role,
    login_just_for_drivers,
    isNeedAccessibility,
    showDriverNoLoginPermission,
    driver_have_no_login_permission_to_route,
    please_contact_manager,
  }
}

export default connect(mapStateToProps, {
  onUserSignInClick,
  onDriverSignInClick,
  readStationIdFromBgService,
  readBgServiceConfiguration,
  resetAllReducers,
  changeDisplayedLanguage,
  reportSuspiciousBrokenLockers,
  logScreenEnterance,
  clearLoginErrStatus,
  takePicture,
})(LoginScreen)
