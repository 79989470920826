import React from "react"
import Modal from "react-modal"
import { OrangeButton } from "../../common"
import { getDateOutOfTimeStamp, getTimeOutOfTimeStamp } from "../../../utils"
import "./style.scss"
import Loader from "../../common/Loader"

const WaitingForLockerLock = ({
  show,
  onCloseClicked,
  onUnresponsiveDoorClick,
  title,
  subTitle,
  orderData,
  barcode,
  onOopsClicked,
  dirRtl,
  cancelText,
}) => {
  const renderUnresponsiveDoorClick = (onUnresponsiveDoorClick) => {
    if (!onUnresponsiveDoorClick) {
      return
    }

    return (
      <div
        className="wfll-unresp-door-extra-class"
        onClick={() => onUnresponsiveDoorClick()}
      >
        לא זוהתה סגירת הדלת? לחץ כאן
      </div>
    )
  }

  const renderOopsButton = (onOopsClicked) => {
    if (!onOopsClicked) {
      return
    }

    return (
      <OrangeButton onClick={(e) => onOopsClicked()} extraClass="wfll-oops-btn">
        {cancelText}
      </OrangeButton>
    )
  }

  const renderOrderData = (orderData, barcode) => {
    if (!orderData) {
      return
    }

    const { orderNumber, firstName, lastName, orderDate, customerName } =
      orderData

    let customerNameDisplay = customerName
    if (
      !customerNameDisplay ||
      customerNameDisplay == null ||
      customerNameDisplay.length < 2
    ) {
      customerNameDisplay = `${firstName} ${lastName}`
    }

    const displayOrderDate = getDateOutOfTimeStamp(orderDate)
    const displayOrderTime = getTimeOutOfTimeStamp(orderDate)

    return (
      <div className="wfll-details-order">
        <div className="wfll-details-order-wrapper wfll-details-order-bold">
          <div>{customerNameDisplay}</div>
        </div>
        <div className="wfll-details-order-wrapper">
          <div>{orderNumber}</div>
          <div>{displayOrderDate}</div>
          <div>{displayOrderTime}</div>
        </div>
        {barcode && (
          <div className="wfll-details-order-wrapper barcode">
            <div className="barcode-title">ברקוד</div>
            <div className="barcode-text">{barcode}</div>
          </div>
        )}
      </div>
    )
  }

  return (
    <Modal
      isOpen={show}
      onRequestClose={onCloseClicked}
      className="react-modal-content"
      overlayClassName="modal-overlay"
    >
      {/* {renderCloseBtn(onCloseClicked)} */}
      {renderUnresponsiveDoorClick(onUnresponsiveDoorClick)}

      <div className="wfll-label-extra-class" dir={dirRtl ? "rtl" : "ltr"}>
        {title}
      </div>
      <div className="wfll-label-extra-class" dir={dirRtl ? "rtl" : "ltr"}>
        {subTitle}
      </div>
      {renderOrderData(orderData, barcode)}
      <Loader />
      {renderOopsButton(onOopsClicked)}
    </Modal>
  )
}

export default WaitingForLockerLock
