import React, { Component } from "react"
import { connect } from "react-redux"
import axios from "axios"
import api from "../../../../api/AzureApi"
import {
  LogoutUser,
  logScreenEnterance,
  onDriverCollectsOrder,
  openAndListenToSpecificLockerLock,
  setPageAsVisited,
  reportEmptyLocker,
  clearNetworkErrorMessage,
  setNetworkErrorIfExist,
} from "../../../../actions"
import CollectWithBarcodeModal from "../../../Modals/CollectWithBarcodeModal"
import ShowOrderQRcodeModal from "../../../Modals/ShowOrderQRcodeModal"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"
import LoadingInfoModal from "../../../Modals/LoadingInfoModal"
import WaitingForLockerLock from "../../../Modals/WaitingForLockerLock"
import { FixedFooter, FixedHeader, LockerCells } from "../../../reduxified"
import CheckLockerModal from "../../../Modals/CheckLockerModal"
import YesNoInfoModal from "../../../Modals/YesNoInfoModal"
import WarningInfoModal from "../../../Modals/WarningInfoModal"
import "./style.scss"

class CollectOrdersScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showWaitModal: false,
      orderData: null,
      showOpeningLockerFailModal: false,
      lockerNum: null,
      orderId: null,
      lockerId: null,
      barcodePopUpDisplay: false,
      orderBarcode: null,
      currentlyOpeningALocker: false,
      nextMaxAttempts: 0,
      enableUnresponsiveDoorBtn: false,
      cellNum: null,
      lockerData: null,
      isUsedBarcode: false,
      isExequted: {
        doorOpened: false,
        doorClosed: false,
      },

      showQRcodeModal: false,
      showQBarcodeModal: false,
      showEmptyLockerShurePopup: false,

      showCheckLockerPopup: false,
      showErrorDriverCollectPopup: false,
    }
  }

  componentDidMount() {
    const { authenticated } = this.props
    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }

    this.props.setPageAsVisited("CollectOrdersScreen")
    this.props.logScreenEnterance("DRIVER_COLLECT_ORDER_SCREEN")
  }

  async componentWillReceiveProps(newProps) {
    const {
      openedLockersArr,
      errorLockersArr,
      stationId,
      openingAttempsMap,
      recentClosedLockersArr,
      recentOpenAttemptLockerNum,
      closedLocker,
      role,
      errorDriverCollect,
    } = newProps

    const {
      lockerNum,
      orderId,
      lockerId,
      nextMaxAttempts,
      orderBarcode,
      isExequted,
    } = this.state
    if (!lockerNum) {
      return
    }

    if (lockerNum !== recentOpenAttemptLockerNum) {
      return
    }

    const { orderData } = this.state
    const openingDoorSuccess = openedLockersArr.includes(lockerNum)
    const openingErrorOccurred = errorLockersArr.includes(lockerNum)
    const closedDoorSuccess = recentClosedLockersArr.includes(lockerNum)
    const openAttemptsNum = openingAttempsMap[lockerNum] || 0

    if (closedDoorSuccess && !isExequted.doorClosed) {
      console.log("closedDoorSuccess")

      const isOccupiedLocker = closedLocker.isOccupied === 1
      const canReprtEmptyLocker =
        orderData != null &&
        (orderData.orderStatus === 0 || orderData.orderStatus === 13) &&
        (role === "Manager" || role === "DoneDriver" || role === "Driver")

      this.setState({
        enableUnresponsiveDoorBtn: false,
        showWaitModal: false,
        isExequted: {
          ...this.state.isExequted,
          doorClosed: true,
        },
        showCheckLockerPopup: isOccupiedLocker || canReprtEmptyLocker,
      })
      return
    }

    if (openingDoorSuccess && !isExequted.doorOpened) {
      console.log("openingDoorSuccess")

      this.setState({
        showWaitModal: true,
        currentlyOpeningALocker: false,
        enableUnresponsiveDoorBtn: false,
        isExequted: {
          ...this.state.isExequted,
          doorOpened: true,
        },
      })

      orderBarcode === null &&
        (await this.props.onDriverCollectsOrder(orderId, lockerId, stationId))

      setTimeout(
        () => this.setState({ enableUnresponsiveDoorBtn: true }),
        10 * 1000
      )
      return
    }

    if (openingErrorOccurred) {
      console.log("openingErrorOccurred")
      if (openAttemptsNum < nextMaxAttempts) {
        this.openAndListenToLocker(lockerNum, 5)
      } else {
        this.setState({
          showOpeningLockerFailModal: true,
          currentlyOpeningALocker: false,
        })
      }
    }
    if (errorDriverCollect) {
      this.setState({
        showErrorDriverCollectPopup: true,
        showCheckLockerPopup: false,
        showReprtEmptyLocker: false,
        showIfOccupiedLocker: false,
      })
      return
    }
    if (newProps.closedLocker !== this.props.closedLocker) {
      const { role } = newProps
      const { orderData } = this.state
      const isOccupied = closedLocker.isOccupied === 1
      const showReprtEmptyLocker =
        orderData != null &&
        (orderData.orderStatus === 0 || orderData.orderStatus === 13) &&
        (role === "Manager" || role === "DoneDriver" || role === "Driver")

      this.setState({
        showCheckLockerPopup: isOccupied || showReprtEmptyLocker,
      })
      return
    }
  }

  onCancelClick() {
    this.props.history.goBack()
  }

  onCellClick(cellNum, lockerData) {
    const { lockerId, order, disabled, pickUpDisabled } = lockerData

    if (disabled || pickUpDisabled || !order) {
      return
    }

    const {
      orderId,
      orderStatus,
      barcodePopUpDisplay,
      haveQRcode,
      externalOrderNumber,
    } = order

    if (orderStatus !== 0 && orderStatus !== 13) {
      return
    }
    this.setState({
      orderData: order,
      lockerData: lockerData,
      cellNum: cellNum,
      isUsedBarcode: false,
      orderBarcode: null,
    })

    if (orderStatus === 13 && haveQRcode && externalOrderNumber) {
      this.setState({ showQRcodeModal: true })
    } else {
      barcodePopUpDisplay
        ? this.setState({ showBarcodeModal: true })
        : this.openCellAndWaitForClose(cellNum, orderId, lockerId)
    }
  }

  openCellAndWaitForClose(lockerNum, orderId, lockerId) {
    this.openAndListenToLocker(lockerNum)

    const { openingAttempsMap } = this.props

    const openAttemptsNum = openingAttempsMap[lockerNum] || 0

    this.setState({
      lockerNum,
      orderId,
      lockerId,
      currentlyOpeningALocker: true,
      nextMaxAttempts: openAttemptsNum + 4,
      isExequted: {
        doorOpened: false,
        doorClosed: false,
      },
    })
  }

  openAndListenToLocker(lockerNum, attempts) {
    const { stationId, stationPaymentVersion } = this.props
    this.props.openAndListenToSpecificLockerLock(
      lockerNum,
      attempts,
      stationId,
      undefined, //packageNumber
      3, //it's lockerStatus (2 means 'full', 3 means 'empty')
      stationPaymentVersion
    )
  }

  async onAddingBarcode(barcode) {
    const { lockerData, cellNum } = this.state
    const { lockerId, order } = lockerData
    const { orderId, orderType } = order
    const { stationId } = this.props

    await axios
      .get(api.isUsedBarcode(barcode, orderType))
      .then((res) => {
        this.setState({ isUsedBarcode: res.data })
      })
      .catch((err) => {
        this.props.setNetworkErrorIfExist(err)
      })

    const { isUsedBarcode } = this.state

    if (isUsedBarcode) {
      this.setState({
        showOpeningLockerFailModal: true,
      })
      return
    }
    this.setState({ orderBarcode: barcode })
    this.openCellAndWaitForClose(cellNum, orderId, lockerId)
    console.log("orderId:" + orderId)

    await this.props.onDriverCollectsOrder(
      orderId,
      lockerId,
      stationId,
      barcode
    )
  }

  async onContinueWithQRcode() {
    const { lockerData, cellNum } = this.state
    const { lockerId, order } = lockerData
    const { orderId, packageNumber } = order
    const { stationId } = this.props
    this.setState({ orderBarcode: packageNumber, showQRcodeModal: false })
    this.openCellAndWaitForClose(cellNum, orderId, lockerId)
    console.log("orderId:" + orderId)

    await this.props.onDriverCollectsOrder(
      orderId,
      lockerId,
      stationId,
      packageNumber
    )
  }

  reportEmptyLocker() {
    const { lockerId, order } = this.state.lockerData
    const { orderId } = order
    this.props.reportEmptyLocker(orderId, lockerId)
    this.setState({ showCheckLockerPopup: false })
  }

  render() {
    const {
      showWaitModal,
      showOpeningLockerFailModal,
      showBarcodeModal,
      showQRcodeModal,
      orderData,
      lockerNum,
      currentlyOpeningALocker,
      enableUnresponsiveDoorBtn,
      cellNum,
      showEmptyLockerShurePopup,

      showCheckLockerPopup,
    } = this.state
    const {
      driver_menu_orders_collecting,
      close_text,
      next_text,
      order_details,
      user_name_text,
      order_number,
      package_number,
      locker_number,
      dirRtl,
      emptyLockerBtnText,
      infoEmptyLockerText1,
      infoEmptyLockerText2,
      checkAgainIfLockerIsEmpty,
      role,
      driver_must_collect_first,
      isAnyOrderToCollect,
      isMustCollectFirstRole,
      lockersToCollect,
      closedLocker,
      cancel_operation,
      errorDriverCollect,
      driver_collect_failed_title,
      driver_collect_failed_text,
    } = this.props

    const showIfOccupiedLocker = this.props.closedLocker.isOccupied === 1
    const showReprtEmptyLocker =
      orderData != null &&
      (orderData.orderStatus === 0 || orderData.orderStatus === 13) &&
      (role === "Manager" || role === "DoneDriver" || role === "Driver")

    const handleUnresponsiveDoor = enableUnresponsiveDoorBtn
      ? () =>
          this.setState({
            showWaitModal: false,
            enableUnresponsiveDoorBtn: false,
          })
      : null

    return (
      <div className="ls-wrapper">
        <FixedHeader buttonsFlag="collectOrdersScreen" />
        <div className="driver-screen-title">
          {driver_menu_orders_collecting}
        </div>

        <div className="dcfacs-inner-wrapper">
          <LockerCells onCellClick={this.onCellClick.bind(this)} />
        </div>
        {isAnyOrderToCollect && (
          <div
            className="driver-must-collect-first"
            dir={dirRtl ? "rtl" : "ltr"}
          >
            {driver_must_collect_first}
            <br />
            {lockersToCollect}
          </div>
        )}
        <FixedFooter history={this.props.history} />

        <WaitingForLockerLock
          show={showWaitModal}
          title={`תא ${lockerNum} פתוח, סגור תא זה להמשך`}
          subTitle="פרטי ההזמנה"
          orderData={orderData}
          barcode={this.state.orderBarcode}
          onUnresponsiveDoorClick={handleUnresponsiveDoor}
          dirRtl={dirRtl}
          onCloseClicked={() => this.setState({ showWaitModal: false })}
          cancelText={cancel_operation}
        />

        <CollectWithBarcodeModal
          show={showBarcodeModal}
          onAddingBarcode={this.onAddingBarcode.bind(this)}
          dirRtl={dirRtl}
          orderData={orderData}
          lockerNum={cellNum}
          btnText={next_text}
          onCloseClicked={() => this.setState({ showBarcodeModal: false })}
          title={order_details}
          user_name_text={user_name_text}
          order_number={order_number}
          locker_number={locker_number}
        />

        <ShowOrderQRcodeModal
          show={showQRcodeModal}
          dirRtl={dirRtl}
          orderData={orderData}
          lockerNum={cellNum}
          btnText={next_text}
          onCloseClicked={() => this.setState({ showQRcodeModal: false })}
          title={order_details}
          user_name_text={user_name_text}
          order_number={order_number}
          package_number={package_number}
          locker_number={locker_number}
          onClickContinue={(e) => this.onContinueWithQRcode(e)}
        />

        <LoadingInfoModal
          show={currentlyOpeningALocker}
          title={`פותח את תא מספר ${lockerNum}`}
          text="...אנא המתן"
        />
        <ErrorInfoModal
          show={showOpeningLockerFailModal}
          title={
            !this.state.isUsedBarcode
              ? "תקלה בעת ניסיון פתיחת הדלת"
              : "כבר קיימת הזמנה עם ברקוד הזה"
          }
          text={
            !this.state.isUsedBarcode
              ? "אירעה תקלה בעת ניסיון פתיחת הדלת לאחר 4 פעמים"
              : "נא בחרו את ברקוד אחר"
          }
          onModalCloseClick={() =>
            this.setState({ showOpeningLockerFailModal: false })
          }
          closeTrnsl={close_text}
        />
        <CheckLockerModal
          show={showCheckLockerPopup}
          onModalCloseClick={() =>
            this.setState({
              showCheckLockerPopup: false,
              showReprtEmptyLocker: false,
              showIfOccupiedLocker: false,
            })
          }
          title={checkAgainIfLockerIsEmpty}
          showReprtEmptyLocker={showReprtEmptyLocker}
          showIfOccupiedLocker={showIfOccupiedLocker}
          reportEmptyLocker={() => {
            this.setState({
              showEmptyLockerShurePopup: true,
              showCheckLockerPopup: false,
              showReprtEmptyLocker: false,
              showIfOccupiedLocker: false,
            })
          }}
          emptyLockerBtnText={emptyLockerBtnText}
          infoEmptyLockerText1={infoEmptyLockerText1}
          infoEmptyLockerText2={infoEmptyLockerText2}
          dirRtl={dirRtl}
          closeBtnText={close_text}
        />
        <YesNoInfoModal
          show={showEmptyLockerShurePopup}
          onModalOkClick={() => {
            this.reportEmptyLocker()
            this.setState({ showEmptyLockerShurePopup: false })
          }}
          onModalCancelClick={() =>
            this.setState({ showEmptyLockerShurePopup: false })
          }
          title={"?האם ברצונך לדווח על חוסר הזמנה"}
          text={""}
          okTransl={"דווח"}
          cancelTransl={close_text}
          dirRtl={dirRtl}
        />

        <WarningInfoModal
          show={this.props.showNetworkErrorMessage}
          title="שים לב"
          text="יש כרגע בעיית תקשורת בעמדה."
          onModalCloseClick={() => this.props.clearNetworkErrorMessage()}
          btnText={close_text}
          dirRtl={dirRtl}
        />
        <WarningInfoModal
          show={this.state.showErrorDriverCollectPopup}
          title={driver_collect_failed_title}
          text={driver_collect_failed_text}
          onModalCloseClick={() => {
            this.setState({
              showErrorDriverCollectPopup: false,
              showCheckLockerPopup: false,
              showReprtEmptyLocker: false,
              showIfOccupiedLocker: false,
            })
          }}
          btnText={close_text}
          dirRtl={dirRtl}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  bgService,
  lockersCells,
  translate,
  stationServiceTypes,
  lockers,
  errorMessages,
}) => {
  const { authenticated, role, isMustCollectFirstRole } = userLogin
  const { stationId } = bgService
  const { stationPaymentVersion } = stationServiceTypes
  const {
    openedLockersArr,
    errorLockersArr,
    openingAttempsMap,
    recentClosedLockersArr,
    recentOpenAttemptLockerNum,
    isNeedToReloadLockersStatuses,
    isAnyOrderToReturn,
    isAnyOrderToCollect,
    lockersToCollect,
    errorDriverCollect,
  } = lockersCells

  const { closedLocker } = lockers
  const {
    driver_menu_orders_collecting,
    close_text,
    next_text,
    order_details,
    driver_interface_locker_screen_order_client_name_text: user_name_text,
    order_number,
    package_number,
    locker_number,
    dirRtl,
    emptyLockerBtnText,
    infoEmptyLockerText1,
    infoEmptyLockerText2,
    checkAgainIfLockerIsEmpty,
    driver_must_collect_first,
    cancel_operation,
    driver_collect_failed_title,
    driver_collect_failed_text,
  } = translate
  const { showNetworkErrorMessage } = errorMessages
  return {
    stationPaymentVersion,
    stationId,
    authenticated,
    role,
    openedLockersArr,
    errorLockersArr,
    openingAttempsMap,
    recentClosedLockersArr,
    recentOpenAttemptLockerNum,
    driver_menu_orders_collecting,
    close_text,
    next_text,
    order_details,
    user_name_text,
    order_number,
    package_number,
    locker_number,
    dirRtl,
    isNeedToReloadLockersStatuses,
    closedLocker,
    emptyLockerBtnText,
    infoEmptyLockerText1,
    infoEmptyLockerText2,
    checkAgainIfLockerIsEmpty,
    showNetworkErrorMessage,
    driver_must_collect_first,
    isAnyOrderToReturn,
    isAnyOrderToCollect,
    isMustCollectFirstRole,
    lockersToCollect,
    cancel_operation,
    errorDriverCollect,
    driver_collect_failed_title,
    driver_collect_failed_text,
  }
}

export default connect(mapStateToProps, {
  onDriverCollectsOrder,
  openAndListenToSpecificLockerLock,
  logScreenEnterance,
  setPageAsVisited,
  reportEmptyLocker,
  LogoutUser,
  clearNetworkErrorMessage,
  setNetworkErrorIfExist,
})(CollectOrdersScreen)
