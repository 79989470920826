import React, { Component } from "react"
import { connect } from "react-redux"
import { isProdEnv } from "../../../../utils"
import {
  onUserSignInClick,
  readStationIdFromBgService,
  readBgServiceConfiguration,
  resetAllReducers,
  changeDisplayedLanguage,
  fetchAllLockersStatuses,
  getAllOpenedLockers,
  reportSuspiciousBrokenLockers,
  logScreenEnterance,
  fetchStationServiceTypes,
  LogoutUser,
} from "../../../../actions"
import { AppVersion } from "../../../../utils/Env"
import { FixedHeader, FixedFooter } from "../../../reduxified"
import ServiceMenuButton from "../../../common/ServiceMenuButton"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"
import SelectIfRetunIsLabelLessModal from "../../../Modals/SelectIfRetunIsLabelLessModal"
import "./style.scss"

class ServiceDeliveryMenuScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorsTimeout: null,
      showErrorModal: false,
      runValidationsTimeOut: null,
      openedDoorModalCloseClicked: false,
      showSelectIfRetunIsLabelLessModal: false,
    }
  }

  componentDidMount() {
    if (!this.props.stationId || this.props.stationId.length < 1) {
      this.props.history.push("/")
    }
    this.props.resetAllReducers()
    this.props.LogoutUser()
    this.props.readStationIdFromBgService()
    this.props.readBgServiceConfiguration()
    const { maxLockerNumber, brokenLockersNumArr } = this.props
    this.props.getAllOpenedLockers(maxLockerNumber, brokenLockersNumArr)
    this.props.logScreenEnterance("SERVICE_DELIVERY_MENU_SCREEN")
  }

  componentWillReceiveProps(newProps) {
    const { errTitle, errText, stationId } = newProps

    //! TODOD- RESET THE ERR TITLE AND TEXT WHEN STARTING NEW REQ!
    const {
      errTitle: oldErrTitle,
      errText: oldErrText,
      maxLockerNumber: oldMaxLockerNum,
    } = this.props

    if (oldErrTitle !== errTitle || oldErrText !== errText) {
      setTimeout(
        () =>
          this.setState({
            showErrorModal: true,
          }),
        250
      )
    }
  }

  onLangChange(lang) {
    const { stationId } = this.props
    this.props.fetchStationServiceTypes(stationId)
    this.props.changeDisplayedLanguage(lang)
  }

  onWarningOpenedDoorsModalOkClicked() {
    const { maxLockerNumber, brokenLockersNumArr } = this.props

    this.props.getAllOpenedLockers(maxLockerNumber, brokenLockersNumArr)
  }

  onOpenedDoorWarningModalCloseClicked() {
    console.log("onOpenedDoorWarningModalCloseClicked")

    const { stationId, lockersWithOpenStatusFromHw } = this.props

    this.setState({ openedDoorModalCloseClicked: true })

    this.props.reportSuspiciousBrokenLockers(
      stationId,
      lockersWithOpenStatusFromHw
    )
  }

  onPrintedReturnSelected() {
    this.props.history.push("/ShipingPackageScreen/ScanBarcodeOnWaybill", "dhl")
  }

  onLabelLessReturnSelected() {
    this.props.history.push("/ShipingPackageScreen/ScanQRcodeForReturn", "dhl")
  }

  render() {
    const { showErrorModal } = this.state

    const {
      errTitle,
      errText,
      dirRtl,
      login_menu_title,
      close_text,
      isLockerToLockerDeliveryActive,
      isReturnPackageActive,
      isReturnExelotActive,
      main_title,
      locker_to_locker_done_delivery,
      //return_package,

      return_package_returnsExternal,
      shipping_return_package,
      //shipping_return_package_hfd,
      dhl_package_delivery_info,
      //exelot_package_delivery_info,
      select_identification_type_title,
      labelless_return_title,
      labelless_return_info,
      printed_return_title,
      printed_return_info,
      menuCount,
    } = this.props

    const wrapperStyle =
      "ls-wrapper service-delivery-menu" + (menuCount > 3 ? " thin-button" : "")
    console.log("wrapperStyle: " + wrapperStyle)
    return (
      <div className={wrapperStyle}>
        {!isProdEnv() && (
          <div className="testing-version">{`TESTING v${AppVersion}`}</div>
        )}
        <FixedHeader title={login_menu_title} subTitle="" />
        <div className="main-title">{main_title}</div>

        <div className="services-wrapper" dir={dirRtl ? "rtl" : "lrt"}>
          <ServiceMenuButton
            show={isLockerToLockerDeliveryActive}
            serviceType="locker-delivery"
            text={locker_to_locker_done_delivery}
            onClick={() =>
              this.props.history.push(
                "LockerToLockerDeliveryScreen/info",
                "lockerDelivery"
              )
            }
            dirRtl={dirRtl}
          />
          <ServiceMenuButton
            show={isReturnPackageActive}
            serviceType="dhl"
            text={shipping_return_package}
            onClick={() => {
              this.setState({ showSelectIfRetunIsLabelLessModal: true })
            }}
            infoText={dhl_package_delivery_info}
            dirRtl={dirRtl}
          />
          {
            //<ServiceMenuButton
            // show={isReturnExelotActive}
            // serviceType="exelot"
            // text={shipping_return_package}
            // onClick={() =>
            //  this.props.history.push(
            //     //"/ShipingPackageScreen/SenderDetails",
            //     "/ShipingPackageScreen/ScanBarcodeOnWaybill",
            //    "exelot"
            //   )
            // }
            // infoText={exelot_package_delivery_info}
            // dirRtl={dirRtl}
            ///>
          }
          {/*<ServiceMenuButton
            show={isReturnPackageActive}
            serviceType="hfd"
            text={shipping_return_package_hfd}
            onClick={() =>
              this.props.history.push(
                //"/ShipingPackageScreen/SenderDetails",
                "/ShipingPackageScreen/ScanBarcodeOnWaybill",
                "hfd"
              )
            }
            infoText=""
            dirRtl={dirRtl}
          />*/}
          <ServiceMenuButton
            show={isReturnPackageActive}
            serviceType="ups"
            text={shipping_return_package}
            onClick={() =>
              this.props.history.push(
                "/ShipingPackageScreen/DeliverySmsLogin",
                "ups"
              )
            }
            infoText={""}
            dirRtl={dirRtl}
          />
          <ServiceMenuButton
            show={isReturnExelotActive}
            serviceType="returnsExternal"
            text={return_package_returnsExternal}
            onClick={() =>
              this.props.history.push(
                "/ShipingPackageScreen/DeliverySmsLogin",
                "returnsExternal"
              )
            }
            infoText={""}
            dirRtl={dirRtl}
          />
          {/*  
          <ServiceMenuButton
            show={isReturnExelotActive}
            serviceType="oneProject"
            text={return_package}
            onClick={() =>
              this.props.history.push(
                "/ShipingPackageScreen/DeliverySmsLogin",
                "oneProject"
              )
            }
            infoText={""}
            dirRtl={dirRtl}
          />
          */}
        </div>

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showErrorModal && errText && errText}
          title={errTitle}
          text={errText}
          onModalCloseClick={() => this.setState({ showErrorModal: false })}
          closeTrnsl={close_text}
        />

        <SelectIfRetunIsLabelLessModal
          show={this.state.showSelectIfRetunIsLabelLessModal}
          dirRtl={dirRtl}
          onLabelLessClick={() => this.onLabelLessReturnSelected()}
          onPrintedClick={() => this.onPrintedReturnSelected()}
          onModalCloseClick={() =>
            this.setState({ showSelectIfRetunIsLabelLessModal: false })
          }
          texts={{
            title: select_identification_type_title,
            btnCloseText: close_text,
            labellessTitle: labelless_return_title,
            labellessInfo: labelless_return_info,
            printedTitle: printed_return_title,
            printedInfo: printed_return_info,
          }}
        />

        {/* <OpenedDoorsWarningModal
          show={
            lockersWithOpenStatusFromHw.length > 0 &&
            !openedDoorModalCloseClicked
          }
          openedDoorsArr={lockersWithOpenStatusFromHw}
          onModalOkClick={() => this.onWarningOpenedDoorsModalOkClicked()}
          onModalCloseClick={() => this.onOpenedDoorWarningModalCloseClicked()}
        /> */}

        <FixedFooter showDriverLogin history={this.props.history} />
      </div>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  bgService,
  lockersCells,
  translate,
  stationServiceTypes,
}) => {
  const { stationId } = bgService

  const { maxLockerNumber, lockersWithOpenStatusFromHw, brokenLockersNumArr } =
    lockersCells
  const {
    authenticated,
    errTitle,
    errText,
    isLoadingRequest,
    isPasswordTemporary,
    userId,
  } = userLogin
  const {
    dirRtl,
    langName,
    welcome_title_text,
    login_menu_title,
    login_menu_delivery,
    login_menu_driver,
    close_text,
    system_updgrade_err_msg,
    main_title,
    rent_a_locker,
    locker_delivery,
    locker_to_locker_done_delivery,
    shipping_package,
    //return_package,
    return_package_returnsExternal,
    shipping_return_package,
    shipping_return_package_hfd,
    dhl_package_delivery_info,
    //exelot_package_delivery_info,
    select_identification_type_title,
    labelless_return_title,
    labelless_return_info,
    printed_return_title,
    printed_return_info,
  } = translate

  const {
    isDeliveryActive,
    isLockerToLockerDeliveryActive,
    isReturnPackageActive,
    isReturnExelotActive,
  } = stationServiceTypes

  let menuCount = 0
  menuCount = isLockerToLockerDeliveryActive ? menuCount + 1 : menuCount
  menuCount = isReturnPackageActive ? menuCount + 1 : menuCount
  menuCount = isReturnPackageActive ? menuCount + 1 : menuCount
  //menuCount = isReturnExelotActive ? menuCount + 1 : menuCount
  menuCount = isReturnExelotActive ? menuCount + 1 : menuCount

  console.log("menuCount:" + menuCount)

  return {
    authenticated,
    errTitle: translate[errTitle],
    errText: translate[errText],
    isLoadingRequest,
    isPasswordTemporary,
    userId,
    stationId,
    maxLockerNumber,
    lockersWithOpenStatusFromHw,
    brokenLockersNumArr,
    main_title,
    dirRtl,
    langName,
    welcome_title_text,
    login_menu_title,
    login_menu_delivery,
    login_menu_driver,
    close_text,
    system_updgrade_err_msg,
    isDeliveryActive,
    isLockerToLockerDeliveryActive,
    isReturnPackageActive,
    isReturnExelotActive,
    rent_a_locker,
    locker_delivery,
    locker_to_locker_done_delivery,
    shipping_package,
    //return_package,
    return_package_returnsExternal,
    shipping_return_package,
    shipping_return_package_hfd,
    dhl_package_delivery_info,
    //exelot_package_delivery_info,
    select_identification_type_title,
    labelless_return_title,
    labelless_return_info,
    printed_return_title,
    printed_return_info,
    menuCount,
  }
}

export default connect(mapStateToProps, {
  onUserSignInClick,
  readStationIdFromBgService,
  readBgServiceConfiguration,
  resetAllReducers,
  changeDisplayedLanguage,
  fetchAllLockersStatuses,
  getAllOpenedLockers,
  reportSuspiciousBrokenLockers,
  logScreenEnterance,
  fetchStationServiceTypes,
  LogoutUser,
})(ServiceDeliveryMenuScreen)
