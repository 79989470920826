import React, { Component } from "react"
import { connect } from "react-redux"
import { LogoutUser, recreateUserPassword } from "../../../actions"
import DoneLogo from "../../../assets/svg/done_logo.svg"
import LangSupportPanel from "../../common/LangSupportPanel"
import "./style.css"

class FixedFooter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showYesNoModal: false,
      showPricesList: false,
      showRecreatePasswordModal: false,
      showModal: false,
    }
  }

  onBackClicked() {
    this.props.history.goBack()
  }

  render() {
    const {
      back_btn_text,
      onLangChange,
      history,
      homePage,
      langName,
      hideBack,
      showLogOut,
      logout_btn_text,
    } = this.props

    return (
      <div className={`fixed-footer-wrapper ${this.props.className}`}>
        <div className="logo-wrapper">
          <img className="done-logo" src={DoneLogo} alt="done_logo" />
        </div>
        {homePage ? (
          <LangSupportPanel
            onLangChange={(lang) => onLangChange(lang)}
            langName={langName}
          />
        ) : hideBack ? (
          <></>
        ) : showLogOut ? (
          <div
            className="header-button back-button"
            onClick={() => {
              this.props.LogoutUser()
              this.props.history.push("")
            }}
          >
            {logout_btn_text}
          </div>
        ) : (
          <div
            className="header-button back-button"
            onClick={() =>
              history.location.state === "delivery" ||
              history.location.state === "detailsUpdate"
                ? this.props.LogoutUser()
                : history.goBack()
            }
          >
            {back_btn_text}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ userLogin, prices, bgService, translate }) => {
  const { authenticated, isPasswordTemporary } = userLogin
  const { pricesArrs } = prices
  const { stationId } = bgService

  const {
    dirRtl,
    langName,
    logout_btn_text,
    logout_modal_title,
    logout_modal_text,
    back_btn_text,
    ok_text,
    cancel_text,
    close_text,
    price_list,
    login_menu_driver,
    sms_login_didnot_get_sms,
    forgot_password_btn_text,
    reset_pwd_modal_title,
    reset_pwd_modal_body,
    did_not_get_temp_password,
    register_btn_text,
  } = translate

  return {
    showLogout: authenticated,
    pricesArrs,
    stationId,
    isPasswordTemporary,
    dirRtl,
    langName,
    logout_btn_text,
    logout_modal_title,
    logout_modal_text,
    back_btn_text,
    ok_text,
    cancel_text,
    close_text,
    price_list,
    login_menu_driver,
    sms_login_didnot_get_sms,
    forgot_password_btn_text,
    did_not_get_temp_password,
    reset_pwd_modal_title,
    reset_pwd_modal_body,
    register_btn_text,
  }
}

export default connect(mapStateToProps, {
  LogoutUser,
  recreateUserPassword,
})(FixedFooter)
