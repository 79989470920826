import axios from "axios"
import api from "../api"
import { ON_TAKE_PICTURE_SUCCESS, ON_TAKE_PICTURE_FAILURE } from "./types"

export const takePicture = (data) => {
  return (dispatch) => {
    axios
      .post(api.takePicture, data)
      .then((res) => {
        dispatch({
          type: ON_TAKE_PICTURE_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_TAKE_PICTURE_FAILURE,
          payload: err,
        })
      })
  }
}
