import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { FixedFooter, FixedHeader } from "../../../../reduxified"
import WarningInfoModal from "../../../../Modals/WarningInfoModal"
import { validation } from "../../../../../utils"
import { DisEnbPendBtn, InputFiled, OrangeButton } from "../../../../common"
import VirtalKeyboard from "../../../../common/VirtualKeyboard"
import ErrorInfoModal from "../../../../Modals/ErrorInfoModal"
import { providerManager } from "../../../../../providers/providerManager"
import TermsAndPolicyCb from "../../SignUpScreens/Step2Screen/TermsAndPolicyCb"
import { termsOfUse } from "../../SignUpScreens/Step2Screen/termsOfUse"
import { privacyPolicy } from "../../SignUpScreens/Step2Screen/privacyPolicy"

import {
  logScreenEnterance,
  reportFBEvent,
  setShipingPackageLoading,
  setShipingPackageUser,
  clearShipingPackage,
  fetchAvailableLockersStatus,
  LogoutUser,
} from "../../../../../actions"

import "./style.scss"
import Modal from "react-modal/lib/components/Modal"

class SenderDetails extends Component {
  constructor(props) {
    super(props)

    this.INIT = {
      firstName: "",
      lastName: "",
      mobile: "",
      mobileVerification: "",
      isCheckBoxChecked: false,

      firstNameRef: null,
      lastNameRef: null,
      mobileRef: null,
      mobileVerificationRef: null,

      activeInput: null,
      activeInputName: null,

      mobileErr: false,
      mobileVerificationErr: false,
      firstNameErr: false,
      lastNameErr: false,
      isSubmitBtnDisabled: true,
      errorsTimeout: null,
      showErrorModal: false,
      runValidationsTimeOut: null,
      showOutOfLockersModal: false,
      serviceName: "",
      showLegalInformationModal: false,
      largeModalTitle: "",
      largeModalContent: "",
    }

    this.state = this.INIT
  }

  componentWillUnmount() {
    this.state = this.INIT
  }

  componentDidMount() {
    const { stationId } = this.props
    const from = this.props.history.location.state

    this.setState({ serviceName: this.props.history.location.state })
    if (
      stationId === null ||
      stationId < 0 ||
      stationId === undefined ||
      from === ""
    ) {
      this.props.reportFBEvent(
        "SenderDetailsScreen",
        "No stationId. Redirect to homepage."
      )
      this.props.history.push("/")
    }

    this.props.logScreenEnterance("SENDER_DETAILS")

    this.props.fetchAvailableLockersStatus(stationId)
    const { order } = this.props
    console.log(order)
    if (order.id) {
      this.setState({
        firstName: order.firstName,
        lastName: order.lastName,
        mobile: order.mobilePhone,
      })
    }

    this.runValidationsWithTimoutDebounce()
  }

  componentWillUpdate(newProps, nextState) {
    const { isLoading, isNoAvailableLockersForService } = newProps

    const { showOutOfLockersUpdated } = nextState
    //SHOW NOT AVAILABLE POPUP
    if (
      !isLoading &&
      isNoAvailableLockersForService &&
      !showOutOfLockersUpdated
    ) {
      this.openOutOfLockersModal()
    }

    const { stationId } = newProps

    if (!stationId || stationId < 0) {
      this.props.reportFBEvent(
        "SenderDetailsScreen",
        "No stationId. Redirect to homepage."
      )
      this.props.history.push("/")
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.order !== newProps.order) {
      console.log(newProps.order)
      if (newProps.order.id) {
        debugger
        this.setState({
          firstName: newProps.order.firstName,
          lastName: newProps.order.lastName,
          mobile: newProps.order.mobilePhone,
        })
      }
    }
  }

  openOutOfLockersModal() {
    this.setState({
      showOutOfLockersModal: true,
      showOutOfLockersUpdated: true,
    })
  }

  onKeyboardTextChange(text) {
    const { activeInputName, errorsTimeout } = this.state

    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }

    this.setState({
      [activeInputName]: text,
      mobileErr: false,
      mobileVerificationErr: false,
      firstNameErr: false,
      lastNameErr: false,
      errorsTimeout: null,
    })

    this.runValidationsWithTimoutDebounce()
  }

  onRefReady(refName, ref) {
    if (!this.state[refName]) {
      this.setState({ [refName]: ref })
    }
  }

  onInputFocusChange(refName, isFocused) {
    if (isFocused) {
      this.setState({
        activeInput: this.state[refName],
        activeInputName: refName.replace("Ref", ""),
      })
    } else {
      if (this.state.activeInput === this.state[refName])
        this.setState({ activeInput: null })
    }
  }

  onContinueClick() {
    const { firstName, lastName, mobile } = this.state
    const { stationId } = this.props

    const data = {
      stationId: stationId,
      firstName: lastName,
      lastName: firstName,
      mobilePhone: mobile,
    }
    this.props.setShipingPackageLoading()
    this.props.setShipingPackageUser(data)
    this.props.history.push(
      "/ShipingPackageScreen/SelectLocker",
      this.state.serviceName
    )
  }

  runValidations() {
    const {
      mobile,
      firstName,
      lastName,
      mobileVerification,
      isCheckBoxChecked,
    } = this.state
    const { userMobile } = this.props
    const firstNameLastNameEmpty = firstName.length < 1 || lastName.length < 1
    const isFirstNameValid = validation.NameEnglish(firstName)
    const isLastNameValid = validation.NameEnglish(lastName)

    //mobile
    const isMobileValid =
      validation.Mobile05(mobile) &&
      validation.Mobile(mobile) &&
      mobile !== userMobile
    const isEmptyMobile = mobile.length < 1
    const mobileErr = !isMobileValid && !isEmptyMobile
    //mobileVerificationErr
    const mobileVerificationErr = mobile !== mobileVerification

    const isSubmitBtnDisabled =
      !isMobileValid ||
      firstNameLastNameEmpty ||
      mobileVerificationErr ||
      !isFirstNameValid ||
      !isLastNameValid ||
      !isCheckBoxChecked

    if (!isSubmitBtnDisabled) {
      this.setState({
        isSubmitBtnDisabled,
        mobileErr,
        mobileVerificationErr,
        firstNameErr: !isFirstNameValid,
        lastNameErr: !isLastNameValid,
        runValidationsTimeOut: null,
      })
    } else {
      const errorsTimeout = setTimeout(
        () =>
          this.setState({
            isSubmitBtnDisabled,
            mobileErr,
            mobileVerificationErr,
            firstNameErr: !isFirstNameValid,
            lastNameErr: !isLastNameValid,
            runValidationsTimeOut: null,
          }),
        800
      )

      this.setState({ errorsTimeout })
    }
  }

  onInputFieldChange(inputName, val) {
    this.setState({ [inputName]: val })
    this.runValidationsWithTimoutDebounce()
  }

  onModalFinished() {
    this.setState({
      showLegalInformationModal: false,
    })
  }

  runValidationsWithTimoutDebounce() {
    let { runValidationsTimeOut } = this.state

    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }

    runValidationsTimeOut = setTimeout(() => this.runValidations(), 500)

    this.setState({ runValidationsTimeOut })
  }

  onCloseErrorModal() {
    this.setState(this.INIT)
    this.props.resetCompleteDetails()
  }

  onCheckboxChange(newState) {
    let { isSubmitBtnDisabled } = this.state

    if (!newState) {
      isSubmitBtnDisabled = true
    }

    this.setState({
      isCheckBoxChecked: newState,
      isSubmitBtnDisabled,
    })

    this.runValidationsWithTimoutDebounce()
  }

  onTermsLinkClick() {
    this.setState({
      largeModalTitle: "תנאי שימוש",
      showLegalInformationModal: true,
    })
  }

  onPrivacyPolicyClick() {
    this.setState({
      largeModalTitle: "מדיניות הפרטיות",
      showLegalInformationModal: true,
    })
  }

  render() {
    const {
      firstName,
      lastName,
      mobile,
      mobileVerification,
      activeInputName,
      mobileErr,
      mobileVerificationErr,
      firstNameErr,
      lastNameErr,
      isSubmitBtnDisabled,
      showErrorModal,
      showOutOfLockersModal,
      serviceName,
      isCheckBoxChecked,
      showLegalInformationModal,
      largeModalTitle,
    } = this.state

    const {
      dirRtl,
      sender_details_title,
      first_name_input_hint,
      last_name_input_hint,
      mobile_input_hint,
      mobile_verification_input_hint,
      next_text,
      validationtext,
      password_update_error_title,
      password_update_error_text,
      close_text,
      isLoading,
      can_not_send_to_your_self,
      userMobile,
      all_lockers_full_err_title,
      all_lockers_full_err_body,
      termsCbTranslation,
      back_btn_text,
    } = this.props
    const keyboardLang =
      activeInputName === "mobile" || activeInputName === "mobileVerification"
        ? "mobile"
        : "en"
    const maxLength =
      activeInputName === "mobile" || activeInputName === "mobileVerification"
        ? 10
        : 30

    const validationMobileText =
      mobile === userMobile && mobile && mobile.length > 0
        ? can_not_send_to_your_self
        : validationtext.mobile

    return (
      <div className="sus1s-wrapper sender-wrapper">
        <FixedHeader buttonsFlag="senderDetailsScreen" />
        <div className="sender-title">{sender_details_title}</div>

        {providerManager.renderLogo(serviceName)}

        <div className="sus1s-body" dir={dirRtl ? "rtl" : "ltr"}>
          <div className="sus1s-frame-container">
            <div className="top-90">
              <div className="sus1s-frame-wrapper">
                <div className="input-line-1">
                  <InputFiled
                    value={firstName}
                    onChange={(e) =>
                      this.onInputFieldChange("firstName", e.target.value)
                    }
                    placeholder={first_name_input_hint}
                    onRefReady={(ref) => this.onRefReady("firstNameRef", ref)}
                    onFocusChanged={(isFocused) =>
                      this.onInputFocusChange("firstNameRef", isFocused)
                    }
                    mandatory
                    validationtext={validationtext.nameEnglish}
                    icon="name"
                    dirRtl={dirRtl}
                    displayErr={firstNameErr}
                    disabled={isLoading}
                    tabIndex={1}
                    maxLength={50}
                  />
                  <InputFiled
                    value={lastName}
                    onChange={(e) =>
                      this.onInputFieldChange("lastName", e.target.value)
                    }
                    placeholder={last_name_input_hint}
                    onRefReady={(ref) => this.onRefReady("lastNameRef", ref)}
                    onFocusChanged={(isFocused) =>
                      this.onInputFocusChange("lastNameRef", isFocused)
                    }
                    mandatory
                    validationtext={validationtext.nameEnglish}
                    icon="name"
                    dirRtl={dirRtl}
                    displayErr={lastNameErr}
                    disabled={isLoading}
                    tabIndex={2}
                    maxLength={50}
                  />
                </div>

                <div className="input-line-2">
                  <InputFiled
                    value={mobile}
                    onChange={(e) =>
                      this.onInputFieldChange("mobile", e.target.value)
                    }
                    placeholder={mobile_input_hint}
                    onRefReady={(ref) => this.onRefReady("mobileRef", ref)}
                    onFocusChanged={(isFocused) =>
                      this.onInputFocusChange("mobileRef", isFocused)
                    }
                    displayErr={mobileErr}
                    mandatory
                    dirRtl={dirRtl}
                    maxLength={10}
                    type="tel"
                    icon="mini_phone"
                    validationtext={validationMobileText}
                    disabled={isLoading}
                    tabIndex={3}
                  />
                  <InputFiled
                    value={mobileVerification}
                    onChange={(e) =>
                      this.onInputFieldChange(
                        "mobileVerification",
                        e.target.value
                      )
                    }
                    placeholder={mobile_verification_input_hint}
                    onRefReady={(ref) =>
                      this.onRefReady("mobileVerificationRef", ref)
                    }
                    onFocusChanged={(isFocused) =>
                      this.onInputFocusChange(
                        "mobileVerificationRef",
                        isFocused
                      )
                    }
                    displayErr={mobileVerificationErr}
                    mandatory
                    maxLength={10}
                    type="tel"
                    validationtext={validationtext.mobileVerification}
                    icon="mini_phone"
                    dirRtl={dirRtl}
                    disabled={isLoading}
                    tabIndex={4}
                  />
                </div>
                {
                  //serviceName === "dhl" || serviceName === "exelot" ||serviceName === "hfd" ?
                  <TermsAndPolicyCb
                    isCheckBoxChecked={isCheckBoxChecked}
                    onCheckboxChange={(isCheck) =>
                      this.onCheckboxChange(isCheck)
                    }
                    onTermsClick={() => this.onTermsLinkClick()}
                    onPrivacyClick={() => this.onPrivacyPolicyClick()}
                    translation={termsCbTranslation}
                  /> //: ()
                }
                <DisEnbPendBtn
                  extraClass="btn-margin"
                  onClick={(e) => this.onContinueClick()}
                  isDisabled={isSubmitBtnDisabled}
                  isPending={isLoading}
                >
                  {next_text}
                </DisEnbPendBtn>
              </div>
            </div>
          </div>
        </div>
        <VirtalKeyboard
          currentValue={this.state[activeInputName]}
          onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
          show={this.state.activeInput}
          lang={keyboardLang}
          closeOnEnter
          maxLength={maxLength}
          blockLangSwitch={true}
          dhl
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showErrorModal}
          title={password_update_error_title}
          text={password_update_error_text}
          onModalCloseClick={() => this.onCloseErrorModal()}
          closeTrnsl={close_text}
        />

        <WarningInfoModal
          show={showOutOfLockersModal}
          title={all_lockers_full_err_title}
          text={all_lockers_full_err_body}
          onModalCloseClick={() =>
            this.setState({ showOutOfLockersModal: false })
          }
          btnText={close_text}
        />
        <Modal
          isOpen={showLegalInformationModal}
          onRequestClose={() => this.onModalFinished()}
          className="react-modal-content big-react-modal"
          overlayClassName="modal-overlay"
        >
          <div className="lim-details-container">
            <p className="lim-label-extra-class">{largeModalTitle}</p>
            <iframe
              src="https://done.co.il/policy/"
              className="iframe_policy"
            ></iframe>
            <OrangeButton
              extraClass="lim-close-btn"
              onClick={() => this.onModalFinished()}
            >
              {back_btn_text}
            </OrangeButton>
          </div>
        </Modal>

        <FixedFooter history={this.props.history} showLogOut={true} />
      </div>
    )
  }
}

const mapStateToProps = ({
  translate,
  userLogin,
  bgService,
  shipingPackage,
}) => {
  const { stationId } = bgService
  const { authenticated, userId, mobile } = userLogin
  const userMobile = mobile
  const { order, customer, isLoading, createdOrder } = shipingPackage
  const {
    dirRtl,
    first_name_input_hint,
    last_name_input_hint,
    mobile_input_hint,
    mobile_verification_input_hint,
    next_text,
    signup_cancel_modal_title,
    signup_cancel_modal_body,
    err_title_text,
    ok_text,
    cancel_text,
    close_text,
    create_new_user_exist_mobile_modal_title,
    create_new_user_exist_mobile_modal_text,
    validation_empty_text,
    validation_mobile_text,
    validation_password_text,
    validation_password_verification_text,
    validation_en_name_text,
    validation_email_text,
    validation_email_verification_text,
    validation_mobile_verification_text,
    validation_waybill_text,
    password_update_error_title,
    password_update_error_text,
    sender_details_title,
    can_not_send_to_your_self,
    all_lockers_full_err_title,
    all_lockers_full_err_body,
    im_approving,
    terms_of_use,
    and_the_privacy_policy,
    back_btn_text,
  } = translate
  const termsCbTranslation = {
    im_approving,
    terms_of_use,
    and_the_privacy_policy,
  }

  let validationtext = {
    empty: validation_empty_text,
    mobile: validation_mobile_text,
    password: validation_password_text,
    passwordVerification: validation_password_verification_text,
    nameEnglish: validation_en_name_text,
    email: validation_email_text,
    emailVerification: validation_email_verification_text,
    mobileVerification: validation_mobile_verification_text,
    waybill: validation_waybill_text,
  }

  return {
    authenticated,
    userId,
    userMobile,
    dirRtl,
    first_name_input_hint,
    last_name_input_hint,
    mobile_input_hint,
    mobile_verification_input_hint,
    next_text,
    signup_cancel_modal_title,
    signup_cancel_modal_body,
    err_title_text,
    ok_text,
    cancel_text,
    close_text,
    create_new_user_exist_mobile_modal_title,
    create_new_user_exist_mobile_modal_text,
    validationtext,
    password_update_error_title,
    password_update_error_text,
    sender_details_title,
    can_not_send_to_your_self,
    all_lockers_full_err_title,
    all_lockers_full_err_body,
    termsCbTranslation,
    stationId,
    isLoading,
    order,
    createdOrder,
    customer,
    back_btn_text,
  }
}

export default connect(mapStateToProps, {
  reportFBEvent,
  logScreenEnterance,
  setShipingPackageLoading,
  clearShipingPackage,
  setShipingPackageUser,
  fetchAvailableLockersStatus,
  LogoutUser,
})(withRouter(SenderDetails))
